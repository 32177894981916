<template>
  <div class="container">
    <h1>Test Editor</h1>
    <ckeditor
      :editor="editor"
      v-model="editorData"
      :config="editorConfig"
    ></ckeditor>
  </div>
</template>
<script>
import Editor from "../../ckeditor-build/build/ckeditor";
export default {
  name: "TestEditor",
  data() {
    return {
      editor: Editor,
      editorData: "Ahmed Shreef",
      editorConfig: {
        // The configuration of the editor.
      },
    };
  },
};
</script>
